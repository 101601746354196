import React, { Component } from 'react';
import logo from './logo.svg';
// import doll from './images/Drawing.png'
import './App.css';
import './bootstrap.min.css'
import PropTypes from 'prop-types'

const Title = (props) => {
  return <div>
    <h1 class="display-2 ml-4 mr-4 mt-2"> {props.title} </h1>
    <div class="ml-4 mr-4">Got the domain so I had to use it :D. While I'm building it out please visit my
      <a href="https://docs.google.com/presentation/d/1d06WFntkRqxkF9SFajgjRA0cIi-Yl2V1L3uG8VLyuCc/edit?usp=sharing"> Slides Portfolio</a>
    </div>
    </div>
}

const Image = (props) => {
  return <img src={props.image} alt={props.alt} height="240" width="240" class="ml-4 mt-2"></img>
}

const Project = (props) => {
  return <div>
    <h2> {props.title} </h2>
    <img src={props.image} alt={props.alt} />
  </div> 
}

const Sum = (props) => {
  return <h1> {props.a} + {props.b} = {props.a + props.b}</h1>
}

Sum.propTypes = {
  a: PropTypes.number.isRequired,
  b: PropTypes.number.isRequired
}

class AuthorQuiz extends Component {
  render() {

    return (
      <div class='row'>
        <Image image={require('./images/IMG_0172.JPG')} alt="Profile photo" />
        <Title title='Abraham Hunt' />
      </div>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default AuthorQuiz;
